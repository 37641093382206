import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Offer from '../components/Offer'
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';


const OfferPage = () => {
    const { cat } = useParams();
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Offer category={cat}/>
            <Footer />
        </>
    )
}

export default OfferPage