import React, { useState, useEffect }from 'react'
import { InfoContainer, InfoWrapper, TextWrapper, TopLine, Heading, Subtitle, ImgWrap, Img, Loader } from './SingleArticleElements'

import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
  
const SingleArticle = (par) => {
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchData(paramz) {

            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            const hourRegex = /^\d{2}:\d{2}:\d{2}$/;

            if (!dateRegex.test(paramz.date) || !hourRegex.test(paramz.hour)) {
                navigate('/news'); // Redirect to /news page if the format is invalid
                return;
            }
            const apiName = 'Atenida2';
            const path = `/news/single?date=${paramz.date}&hour=${paramz.hour}`;
            const params = {
                headers: {},
            };

            try {
                const response = await API.get(apiName, path, params);
                if (Object.keys(response).length === 0) {
                    navigate('/news'); // Redirect to /news page if the response is an empty object
                    return;
                }
                setNews(response);
                setLoading(false); // Set loading to false when data is fetched
            } catch (error) {
                console.error(error);
                setLoading(false); // Set loading to false in case of an error
            }
        }
        fetchData(par);
    }, [par]);

    return (
        <>
        <InfoContainer>
            {loading ? ( // Render the loader while loading is true
                <Loader>
                    Pobieranie danych...
                </Loader>
            ) : (
                <>
                    <InfoWrapper>
                        <TextWrapper>
                            <TopLine>{news.category}</TopLine>
                            <Heading lightText={true}>{news.title}</Heading>
                            <ImgWrap>
                                <Img src={news.img}>
                                </Img>
                            </ImgWrap>

                            <div dangerouslySetInnerHTML={{ __html: news.content }}></div>
                            <br></br>
                            <Subtitle>
                                {news.date} {news.hour} Atenida
                            </Subtitle>
                        </TextWrapper>
                    </InfoWrapper>
                </>
            )}
        </InfoContainer>
        </>
      )
    }
    
    export default SingleArticle