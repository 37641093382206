import React, {useState, useEffect} from 'react'
import { 
    Wrapper, 
    RowWrapper, 
    AddButtonWrapper, 
    ViewWrapper, 
    Title, 
    ColumnWrapper, 
    Appointments, 
    Appointment, 
    AppointmentTime, 
    AppointmentName,
    TitleWrap,
    ModalOverlay,
    ModalWrapper,
    CloseButton,
    ModalItems,
    ContentWrapper,
    DeleteButton,
    DeleteButtonWrapper,
    AppointmentText,
} from './CalendarElements'
import { Calendar as Cal } from 'react-calendar';
import './style2.css'
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { API } from 'aws-amplify';
import { Button } from "../ButtonElements";
import uuid from 'react-uuid';

const Calendar = () => {
    const [value, onChangeVal] = useState(new Date());
    const [valueTime, setValueTime] = useState(new Date());
    const [valueTime2, setValueTime2] = useState(new Date());
    const [title, setTitle] = useState('');
    const [appointments, setAppointments] = useState([]);
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    const [refresh, setRefresh] = useState(false);


    const tileContent = ({ date, view }) => {
      if (view === 'month') {
        // Check if the date is in the appointments array
        const isInAppointments = appointments.some(appointment => {
          return new Date(appointment.full_date).getDate() === date.getDate() && new Date(appointment.full_date).getMonth() === date.getMonth() && new Date(appointment.full_date).getFullYear() === date.getFullYear();
        });
        // If the date is in the appointments array, return a div with a red background color
        if (isInAppointments) {
          return "react-calendar__tile--hasActive";
        }
      }
    };


    const onDateChange = (valueNew) => {
      if (valueNew.getMonth() !== value.getMonth() || valueNew.getFullYear() !== value.getFullYear()) {
          onChangeVal(valueNew);
          setRefresh(!refresh);
          console.log("v1");
      }else{
          onChangeVal(valueNew);
          setValueTime(valueNew);
          setValueTime2(valueNew);
          setSelectedAppointmentsFunc(valueNew);
      }
  }


  const setSelectedAppointmentsFunc = (value) => {
    setSelectedAppointments(
        appointments.filter(
          (appointment) =>
          new Date(appointment.full_date).getDate() === value.getDate() && new Date(appointment.full_date).getMonth() === value.getMonth() && new Date(appointment.full_date).getFullYear() === value.getFullYear()
        )
      );
}

    async function fetchData() {
        console.log("fetching new data!");
        const apiName = 'Atenida';
        const path = '/slots';
        const params = {
          queryStringParameters: {
            month: value.getMonth() + 1,
            year: value.getFullYear(),
          },
          headers: {}
        };
        const response = await API.get(apiName, path, params);
        setAppointments(response);
        setSelectedAppointments(
          response.filter(
            (appointment) =>
            new Date(appointment.full_date).getDate() === value.getDate() && new Date(appointment.full_date).getMonth() === value.getMonth() && new Date(appointment.full_date).getFullYear() === value.getFullYear())
        );
      }

      useEffect(() => {
        fetchData();
      }, [refresh]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const displayOutput = () => {
        setIsModalOpen(true);
      };

      const closeModal = () => {
        setIsModalOpen(false);
      };

    async function postData() {
        const apiName = 'Atenida';
        const path = '/add';
        const myInit = {
          body: { 'start' : valueTime,
                    'end' : valueTime2,
                    'title' : title,
        }, 
          headers: {} // OPTIONAL
        };
        setIsModalOpen(false);
        const resp = await API.post(apiName, path, myInit);
        setRefresh(!refresh);
        return resp;
      }

    const postButton = () => {
        postData();
    }

    async function deleteTrigger (dateI, startI) {
        const apiName = 'Atenida';
        const path = '/add';
        const myInit = {
            queryStringParameters: {
                date: dateI,
                start: startI,
        },
            headers: {} // OPTIONAL
        };
        const resp = await API.del(apiName, path, myInit);
        setRefresh(!refresh);
        return resp;
    }

    const deleteButton = async (date, start) => {
        await deleteTrigger(date, start);
        setAppointments(appointments.filter((appointment) => {
          return (appointment.date !== date || appointment.start_hour !== start)
        }));
        setSelectedAppointmentsFunc(value);
    }

    const onBigDateChange = (event) => {
      const { action, activeStartDate, valueNew, view } = event;
      if (action === 'prev' || action === 'next' || action === 'prev2' || action === 'next2' || (action === 'drillDown' && view === 'month')) {
          if (activeStartDate.getMonth() !== value.getMonth() || activeStartDate.getFullYear() !== value.getFullYear()) {
              onChangeVal(activeStartDate);
              setValueTime(activeStartDate);
              setValueTime2(activeStartDate);
              setRefresh(!refresh);
          }
      }
  }
    

  return (
    <>
    <Wrapper>
        <RowWrapper>
            <ViewWrapper>
                <ColumnWrapper>
                    <Cal onChange={onDateChange} value={value} tileClassName={tileContent} onActiveStartDateChange={onBigDateChange}/>
                </ColumnWrapper>
                <ColumnWrapper>
                    <Appointments>
                        <TitleWrap>
                            <Title>
                                {value.toLocaleDateString()}
                            </Title>
                        </TitleWrap>
                        {selectedAppointments.map((appointment) => {
                          const startDate = new Date(appointment.date + "T" + appointment.only_start + "Z");
                          const endDate = new Date(appointment.date + "T" + appointment.end_hour + "Z");

                          const startTime = startDate.toLocaleTimeString([], {timeZone: 'Europe/Warsaw', hour: '2-digit', minute:'2-digit'});
                          const endTime = endDate.toLocaleTimeString([], {timeZone: 'Europe/Warsaw', hour: '2-digit', minute:'2-digit'});

                          return (
                              <Appointment key={uuid()}>
                                  <AppointmentTime>
                                      {startTime}-{endTime}
                                  </AppointmentTime>
                                  <AppointmentName>{appointment.title}</AppointmentName>
                                  {appointment.client === "null" ? '' : <AppointmentText>{appointment.client}</AppointmentText>}
                                  <DeleteButtonWrapper>
                                      <DeleteButton onClick={() => deleteButton(appointment.date, appointment.start_hour)}>&times;</DeleteButton>
                                  </DeleteButtonWrapper>
                              </Appointment>
                          );
                      })}
                        <AddButtonWrapper>
                            <Button onClick={displayOutput}>
                                +
                            </Button>
                        </AddButtonWrapper>
                    </Appointments>
                </ColumnWrapper>
            </ViewWrapper>
        </RowWrapper>
        {isModalOpen && (
        <Modal>
            <div className="modal-content">
                <ContentWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CloseButton onClick={closeModal}>&times;</CloseButton>
                    <Title>Nowy slot {value.toLocaleDateString()}</Title>
                    <ModalItems>
                    <TimePicker
                        ampm={false}
                        label="Start time"
                        value={valueTime}
                        onChange={(newValue) => {
                        setValueTime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TimePicker
                        ampm={false}
                        label="End time"
                        value={valueTime2}
                        onChange={(newValue) => {
                        setValueTime2(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    </ModalItems>
                    <ModalItems>
                    <TextField id="outlined-basic" label="Temat" variant="outlined" fullWidth value={title} 
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}/>
                    </ModalItems>
                    <Button onClick={postButton}>Dodaj</Button>
                    </LocalizationProvider>
                </ContentWrapper>
            </div>
        </Modal>
        )}  
    </Wrapper>
    </>
  )
}

const Modal = ({ children, closeModal }) => {
    return (
        <ModalOverlay>
            <ModalWrapper>
            {children}
            </ModalWrapper>
        </ModalOverlay>
    );
  }

export default Calendar