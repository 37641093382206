import React, {useState} from 'react';
import { Link } from  'react-router-dom'
import './Dropdown2.css';

const Dropdown2 = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <ul onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
          <li key='omnie'>
            <Link to='/omnie' className='dropdown-link' onClick={()=>setClick(false)}>O mnie</Link>
          </li>
          <li key='konsult'>
            <Link to='/konsultacje' className='dropdown-link' onClick={()=>setClick(false)}>Konsultacje</Link>
          </li>
          <li key='coach'>
            <Link to='/life-transition-coach' className='dropdown-link' onClick={()=>setClick(false)}>Life-transition Coach</Link>
          </li>
          <li key='styl'>
            <Link to='/styl-pracy' className='dropdown-link' onClick={()=>setClick(false)}>Metody pracy</Link>
          </li>
          <li key='opinie'>
            <Link to='/opinie' className='dropdown-link' onClick={()=>setClick(false)}>Opinie</Link>
          </li>
        </ul>
    
    </>
  )
}

export default Dropdown2;
