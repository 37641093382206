import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

import { useLocation } from 'react-router-dom';

const Confapi = () => {
    const location = useLocation();
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const conf = async (valueDate, valueHour, stat, mail) => {
        const apiName = 'Atenida';
        const path = '/confirm';
        const params = {
            queryStringParameters: {
              date: valueDate,
              starthour: valueHour,
              status: stat,
              email: mail,
            },
            headers: {}
          };
        try {
            await API.get(apiName, path, params);
            if (stat === 'confirm'){
                setMessage('Confirmation successful!');
            }
            else
            {
                setMessage('Reservation cancelled!');
            }
        } catch (error) {
            console.error(error);
            setMessage('Confirmation/cancellation failed.');
        }
        setOpen(true);
    }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const valueDate = searchParams.get('date');
    const valueHour = searchParams.get('hour');
    const valueStat = searchParams.get('status');
    const valueMail = searchParams.get('email');
    console.log(valueDate, valueHour, valueStat, valueMail);
    conf(valueDate, valueHour, valueStat, valueMail);
    console.log('twice!');
  }, []);
  return (
    <>
    <Collapse in={open}>
        <Alert
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                    setOpen(false);
                }}
                >
                <CloseIcon fontSize="inherit" />
                </IconButton>
            }
            sx={{ mb: 2 }}
            >
            {message}
        </Alert>
    </Collapse>
    </>
  )
}

export default Confapi