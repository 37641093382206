import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import InfoSection from '../components/InfoSection'
import { aboutObjOne, aboutObjTwo, aboutObjThree, aboutObjFour} from '../components/InfoSection/Data'

const About = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    {/* <Hero3 /> */}
    <InfoSection {...aboutObjOne}/>
    <InfoSection {...aboutObjTwo}/>
    <InfoSection {...aboutObjThree}/>
    <InfoSection {...aboutObjFour}/>
    <Footer />
    </>
  )
}

export default About