import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

import { API } from 'aws-amplify';

import { 
    Wrapper, 
    RowWrapper, 
    ViewWrapper, 
} from '../components/Booking/BookingElements'

const DeletePost = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { date, hour } = useParams();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const toggle = () => {
        setIsOpen(!isOpen)
    }
    const deletePost = async () => {
        try {
            const apiName = 'Atenida';
            const path = `/news?date=${date}&hour=${hour}`; // Step 3
            const myInit = {
                // Add your request body or headers if needed
            };

            await API.del(apiName, path, myInit); // Use the DELETE method
            setMessage('Post deleted successfully!');
        } catch (error) {
            console.log('Error deleting post', error);
            setMessage('Deletion failed: ' + error);
        }
        setOpen(true);
    };
  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Wrapper>
        <RowWrapper>
            <ViewWrapper>
                <button onClick={deletePost}>Delete Post</button>
                <Collapse in={open}>
                    <Alert
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {message}
                    </Alert>
                </Collapse>
            </ViewWrapper>
        </RowWrapper>
    </Wrapper>
    <Footer />
    </>
  );
};

export default DeletePost;