import React from 'react'
import { FooterContainer, FooterWrap, FooterLink, SocialMedia, SocialMediaWrap, SocialIcons, SocialIconLink, WebsiteRights } from './FooterElements'
import { FaFacebook, FaInstagram, FaYoutube, FaEnvelope } from 'react-icons/fa'

const Footer = () => {
  return (
    <>
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                    <WebsiteRights>Atenida.pl © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                    <FooterLink href='/privacy' target='_blank'>Polityka prywatności</FooterLink>
                    <SocialIcons>
                        <SocialIconLink href='https://www.facebook.com/groups/1832360620466564/' target='_blank' aria-label='Facebook'>
                            <FaFacebook />
                        </SocialIconLink>
                        <SocialIconLink href='https://www.instagram.com/coaching_by_atenida' target='_blank' aria-label='Instagram'>
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href='https://youtube.com/@atenida' target='_blank' aria-label='Youtube'>
                            <FaYoutube />
                        </SocialIconLink> 
                        <SocialIconLink href='mailto:coaching@atenida.pl' target='_blank' aria-label='Mail'>
                            <FaEnvelope />
                        </SocialIconLink>
                    </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    </>
  )
}

export default Footer