import React, { useState, useEffect }from 'react'
import { InfoContainer, InfoWrapper, PriceWrapper, ItemWrapper, PriceValue, AdminBtnWrap, TopLine, Heading, Subtitle, BtnWrap, Column2, ImgWrap, Img, PaginationContainer, Loader } from './OfferElements'
import { Button } from '../ButtonElements'
import { RequireAdminComp } from '../RequireAuth'
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

  
const Offer = (par) => {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [articlesPerPage] = useState(6);
    const [loading, setLoading] = useState(true); // New loading state
    const toggleHome = () => {
        //scroll 20 px down to avoid navbar covering the top of the page
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData(cat) {
          console.log("fetching new data!");
          const apiName = 'Atenida2';
          const path = `/offer`;
          const params = {
            headers: {}
          };
          if (par.category !== 'sesje' && par.category !== 'pakiety-masterclass' && par.category !== 'kursy' && par.category !== 'all') {
            navigate('/'); // Redirect to /news page if the format is invalid
            return;
            }
          try {
            const response = await API.get(apiName, path, params);
            // Sort the news in descending order by date and hour
            let filteredData = [];
            if (par.category === 'sesje') {
              filteredData = response.filter(item => item.category === 'Konsultacje Indywidualne');
            } else if (par.category === 'pakiety-masterclass') {
              filteredData = response.filter(item => item.category === 'Pakiety Masterclass');
            } else if (par.category === 'kursy') {
              filteredData = response.filter(item => item.category === 'Kursy');
            } else if (par.category === 'all') {
              filteredData = response;
            }
        
            // Sort the news in descending order by date and hour
            setNews(filteredData);
            setLoading(false);
            } catch (error) {
            console.error(error);
            setLoading(false); // Set loading to false in case of an error
            }
        }
        fetchData(par.category);
    }, [par.category]);

    // Pagination logic
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = news.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
      <InfoContainer>
        {loading ? ( // Render the loader while loading is true
                <Loader>
                    Pobieranie danych...
                </Loader>
            ) : (
                <>

                <InfoWrapper>
                    {currentArticles.map((singleNews) => (
                        <ItemWrapper key={singleNews.name}>
                            <TopLine>{singleNews.category}</TopLine>
                            <Heading lightText={true}>{singleNews.name}</Heading>
                            <ImgWrap>
                                <Img src={singleNews.img} />
                            </ImgWrap>
                            <Subtitle darkText={true}>{singleNews.description}</Subtitle>
                            <PriceWrapper>
                                <Subtitle darkText={true}>Cena: </Subtitle>
                                <PriceValue> &nbsp;</PriceValue>
                                <PriceValue> {singleNews.price}</PriceValue>
                            </PriceWrapper>
                            <BtnWrap>
                                <Button to={singleNews.link} onClick={toggleHome}>Przejdź dalej</Button>
                            </BtnWrap>
                            <RequireAdminComp>
                                <AdminBtnWrap>
                                    <Button to={`/deleteoffer/${singleNews.name}`}>DELETE</Button>
                                    <Button to={`/offerEdit?name=${singleNews.name}`}>EDIT</Button>
                                </AdminBtnWrap>
                            </RequireAdminComp>
                        </ItemWrapper>
                    ))}
                </InfoWrapper>
                {/* Pagination */}
                <PaginationContainer>
                    {news.length > articlesPerPage && (
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(news.length / articlesPerPage) }).map((_, index) => (
                        <li key={index} className="page-item">
                            <button
                            onClick={() => paginate(index + 1)}
                            className={`page-link ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                            {index + 1}
                            </button>
                        </li>
                        ))}
                    </ul>
                    )}
                </PaginationContainer>
                </>
            )}
        </InfoContainer>    
    </>
      )
    }
    
    export default Offer