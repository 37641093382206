import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SideBtnWrap, SidebarLink, SidebarMenu, SidebarRoute, SidebarWrapper} from './SidebarElements'
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { animateScroll as scroll } from 'react-scroll'



const Sidebar = ({isOpen, toggle}) => {
    const toggleHome = () => {
        //scroll 20 px down to avoid navbar covering the top of the page
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
      ]);
      const navigate = useNavigate();
    
      function logOut() {
        signOut();
        navigate('/');
      }
  return (
    <>
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {/* <SidebarRoute href='https://www.facebook.com/' target='_blank'  onClick={toggle}>Grupa na Facebooku</SidebarRoute>
                    <SidebarRoute href='https://www.instagram.com' target='_blank'  onClick={toggle}>Konto na Instagramie</SidebarRoute> */}
                    <SidebarLink to='/' onClick={toggleHome}>Strona główna</SidebarLink>
                    <SidebarLink to='/news' onClick={toggleHome}>Artykuły</SidebarLink>
                    <SidebarLink to='/oferta/sesje' onClick={toggleHome}>Konsultacje Indywidualne</SidebarLink>
                    <SidebarLink to='/oferta/kursy' onClick={toggleHome}>Kursy</SidebarLink>
                    <SidebarLink to='/oferta/pakiety-masterclass' onClick={toggleHome}>Pakiety Masterclass</SidebarLink>
                    <SidebarLink to='/booking' onClick={toggleHome}>Terminarz</SidebarLink>
                    <SidebarLink to='/omnie' onClick={toggleHome}>O mnie</SidebarLink>
                    <SidebarLink to='/life-transition-coach' onClick={toggleHome}>Life-transition Coach</SidebarLink>
                    <SidebarLink to='/styl-pracy' onClick={toggleHome}>Metody Pracy</SidebarLink>
                    <SidebarLink to='/opinie' onClick={toggleHome}>Opinie</SidebarLink>
                    <SidebarLink to='/kontakt' onClick={toggleHome}>Kontakt</SidebarLink>
                    {route === 'authenticated' ? (
                        <SidebarLink to='#' onClick={() => logOut()}>Wyloguj</SidebarLink>)
                        : (<SidebarLink to='/login' onClick={toggleHome}>Zaloguj</SidebarLink>)}
                    {/* <SidebarLink href='https://www.facebook.com/groups/1832360620466564/' target='_blank'  onClick={toggle}>Grupa na Facebooku</SidebarLink>
                    <SidebarLink href='https://youtube.com/@atenida' target='_blank'  onClick={toggle}>Kanał na Youtube</SidebarLink>*/}
                </SidebarMenu>
                {/* <SideBtnWrap>
                    <SidebarRoute href='https://www.facebook.com/' target='_blank'  onClick={toggle}>Grupa na Facebooku</SidebarRoute>
                    <SidebarRoute href='https://www.instagram.com' target='_blank'  onClick={toggle}>Konto na Instagramie</SidebarRoute>
                </SideBtnWrap> */}

            </SidebarWrapper>
        </SidebarContainer>
    </>
  )
}

export default Sidebar