import React, {useState} from 'react';
import { Link } from  'react-router-dom'
import './Dropdown.css';

const Dropdown = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <ul onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
          <li key='sesje'>
            <Link to='/oferta/sesje' className='dropdown-link' onClick={()=>setClick(false)}>Konsultacje Indywidualne</Link>
          </li>
          <li key='kursy'>
            <Link to='/oferta/kursy' className='dropdown-link' onClick={()=>setClick(false)}>Kursy</Link>
          </li>
          <li key='pakiety'>
            <Link to='/oferta/pakiety-masterclass' className='dropdown-link' onClick={()=>setClick(false)}>Pakiety Masterclass</Link>
          </li>
          <li key='terminarz'>
            <Link to='/booking' className='dropdown-link' onClick={()=>setClick(false)}>Terminarz</Link>
          </li>
        </ul>
    
    </>
  )
}

export default Dropdown;
