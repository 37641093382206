import { createGlobalStyle } from 'styled-components';
import HelloParis from './fonts/HelloParisSansExtraLight.ttf';

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'HelloParisSansExtraLight';
        src: url(${HelloParis});
        format('truetype');
    }
`;

export default FontStyles;