import React, { useState, useEffect }from 'react'
import { Button } from '../ButtonElements'
import { RequireAdminComp } from '../RequireAuth'
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Column2, ImgWrap, Img, PaginationContainer, Loader } from './NewsElements'

import { API } from 'aws-amplify';

  
const NewsComp = () => {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [articlesPerPage] = useState(5);
    const [loading, setLoading] = useState(true); // New loading state


    useEffect(() => {
        async function fetchData() {
          console.log("fetching new data!");
          const apiName = 'Atenida2';
          const path = '/news';
          const params = {
            // queryStringParameters: {
            //   month: value.getMonth() + 1,
            //   year: value.getFullYear(),
            // },
            headers: {}
          };
          try {
            const response = await API.get(apiName, path, params);
            // Sort the news in descending order by date and hour
            const sortedNews = response.sort((a, b) => {
                // Compare the dates first
                const dateComparison = new Date(b.date) - new Date(a.date);
                if (dateComparison !== 0) {
                    return dateComparison;
                }
                // If the dates are the same, compare the hours
                return b.hour.localeCompare(a.hour);
            });
            setNews(sortedNews);
            setLoading(false);
            } catch (error) {
            console.error(error);
            setLoading(false); // Set loading to false in case of an error
            }
        }
        fetchData();
    }, []);

    // Pagination logic
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = news.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    
  return (
    <>
        <InfoContainer lightBg={true} >
        {loading ? ( // Render the loader while loading is true
                <Loader>
                    Pobieranie danych...
                </Loader>
            ) : (
                <>
                {currentArticles.map((singleNews) => {
                    return (
                
                    <InfoWrapper key={singleNews.date + singleNews.hour}>
                        <InfoRow imgStart={true}>
                            <Column1>
                                <TextWrapper>
                                    <TopLine>{singleNews.category}</TopLine>

                                    <Heading lightText={true}>{singleNews.title}</Heading>

                                    <Subtitle darkText={true}>
                                        {singleNews.description}
                                    </Subtitle>
                                    <Subtitle darkText={true}>
                                        {singleNews.date}
                                    </Subtitle>
                                    <BtnWrap>
                                        <Button to={`/post/${singleNews.date}/${singleNews.hour}`}>
                                            Czytaj dalej...
                                        </Button>
                                    </BtnWrap>

                                    <RequireAdminComp>
                                        <BtnWrap>
                                            <Button to={`/deletepost/${singleNews.date}/${singleNews.hour}`}>
                                                DELETE
                                            </Button>
                                        </BtnWrap>
                                        <BtnWrap>
                                            <Button to={`/newsEdit?date=${singleNews.date}&hour=${singleNews.hour}`}>
                                                EDIT
                                            </Button>
                                        </BtnWrap>
                                    </RequireAdminComp>
                                </TextWrapper>
                            </Column1>
                            <Column2>
                                <ImgWrap>
                                    <Img src={singleNews.img}/>
                                </ImgWrap>
                            </Column2>
                        </InfoRow>
                    </InfoWrapper>
                    )
                })}
                {/* Pagination */}
                <PaginationContainer>
                    {news.length > articlesPerPage && (
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(news.length / articlesPerPage) }).map((_, index) => (
                        <li key={index} className="page-item">
                            <button
                            onClick={() => paginate(index + 1)}
                            className={`page-link ${currentPage === index + 1 ? 'active' : ''}`}
                            >
                            {index + 1}
                            </button>
                        </li>
                        ))}
                    </ul>
                    )}
                </PaginationContainer>
                </>
            )}
        </InfoContainer>    
    </>
  )
}

export default NewsComp