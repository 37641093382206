import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import InfoSection from '../components/InfoSection'
import { helpObjOne, helpObjTwo } from '../components/InfoSection/Data'

const Help = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <InfoSection {...helpObjOne}/>
    <InfoSection {...helpObjTwo}/>
    <Footer />
    </>
  )
}

export default Help