import { useEffect } from "react";

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { useNavigate, useLocation } from 'react-router';

import { Container, FormWrap, Icon, FormContent, NavIcon} from './LoginElements'

import  image from '../../images/ATENIDA-logo2-05.svg'
import { animateScroll as scroll } from 'react-scroll';

const Login = () => {
  const toggleHome = () => {
    scroll.scrollTo(20);
    scroll.scrollToTop()
}

  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    toggleHome();
    if (route === 'authenticated') {
      let search = location.state?.search || '';
      navigate(from + search, { replace: true });
    }
  }, [route, navigate, from]);
  return (
    <View className="auth-wrapper">
        <Container>
            <FormWrap>
                <Icon to="/">
                <NavIcon src={image} />
                </Icon>
                  <FormContent>
                  <Authenticator>
                  </Authenticator>
                  </FormContent>
            </FormWrap>
        </Container>
    </View>
  );
}

export default Login