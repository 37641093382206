import React, { useState, useEffect } from "react";

import Icon1 from "../../images/svg1.svg";
import Icon2 from "../../images/svg3.svg";
import Icon3 from "../../images/svg6.svg";

import { CardContainer, CardH1, CardWrapper, CardCard, CardIcon, CardH2, CardP } from "./CardElements";

import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

const ScrollText = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? 'translateX(0)' : 'translateX(-100px)'};
  transition: all 1.5s ease-in-out;
`;

const Card = () => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleHome = () => {
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }
    useEffect(() => {
        const handleScroll = () => {
            const text = document.querySelector('.scroll-text');
            const textPosition = text.getBoundingClientRect().top;
            const screenHeight = window.innerHeight;
            if (textPosition < screenHeight * 0.8) {
            setIsVisible(true);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <CardContainer id='Card'>
            <CardH1>Co dalej?</CardH1>
            <ScrollText isVisible={isVisible} className="scroll-text">
            <CardWrapper>
                <CardCard to="/konsultacje" onClick={toggleHome}>
                    <CardIcon src={Icon1} />
                    <CardH2>Sesja odkrywcza</CardH2>
                    <CardP>- bezpłatne spotkanie trwające około 30 minut. Dzięki niemu możemy się
poznać i możesz zadać mi dodatkowe pytania przed rozpoczęciem współpracy. Tutaj także
ustalamy wstępny plan działania.</CardP>
                </CardCard>
                <CardCard to="/news" onClick={toggleHome}>
                    <CardIcon src={Icon2} />
                    <CardH2>Poradniki i artykuły</CardH2>
                    <CardP>Na mojej stronie znajdziesz wiele poradników i artykułów, które pomogą Ci zrozumieć proces żałoby i znaleźć sposoby na radzenie sobie z trudnościami.</CardP>
                </CardCard>
                <CardCard to="/opinie" onClick={toggleHome}>
                    <CardIcon src={Icon3} />
                    <CardH2>Opinie po sesjach</CardH2>
                    <CardP>Zobacz, jakie emocje i odczucia mają osoby, które zdecydowały się na moje prowadzenie.</CardP>
                </CardCard>
            </CardWrapper>
            </ScrollText>
        </CardContainer>
    );
}

export default Card;