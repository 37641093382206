import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import FontStyles from './fontStyles';
import { Amplify, Auth } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);
Amplify.configure({
  API: {
      endpoints: [
          {
              name: "Atenida",
              endpoint: "https://ukpru4d6y4.execute-api.eu-central-1.amazonaws.com/prod",
              custom_header: async () => {
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
              }
          },
          {
            name: "Atenida2",
            endpoint: "https://ukpru4d6y4.execute-api.eu-central-1.amazonaws.com/prod"
        }
      ]
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <FontStyles />
    <App />
  </>
);
