import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import InfoSection from '../components/InfoSection'
import { coachObjOne, coachObjTwo } from '../components/InfoSection/Data'

const Coach = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    {/* <Hero3 /> */}
    <InfoSection {...coachObjOne}/>
    <InfoSection {...coachObjTwo}/>
    <Footer />
    </>
  )
}

export default Coach