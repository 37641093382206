import React, { useState, useEffect }from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, BtnWrap, ModalItems} from './NewsEditElements'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';

import { API } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
  

const theme = createTheme({
    palette: {
      primary: {
        light: '#DCD5C9',
        main: '#A1AC9E',
        dark: '#465D56',
        contrastText: '#4E4D4A',
      },
      secondary: {
        light: '#A1AC9E',
        main: '#BEB3A0',
        dark: '#4E4D4A',
        contrastText: '#fff',
      },
    }
  });

const NewsEdit = () => {
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [content, setContent] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const newsDate = queryParams.get('date');
    const newsHour = queryParams.get('hour');

    const isEdit = newsDate !== null && newsHour !== null;

    useEffect(() => {
      // Fetch the news data for editing based on the newsId
      if (isEdit) {
          async function fetchData(nDate, nHour) {
            const apiName = 'Atenida2';
            const path = `/news/single?date=${nDate}&hour=${nHour}`;
            const params = {
                headers: {},
            };

            try {
                const response = await API.get(apiName, path, params);
                setTitle(response.title);
                setCategory(response.category);
                setDescription(response.description);
                setContent(response.content);
            } catch (error) {
                console.error(error);
            }
          }
        fetchData(newsDate, newsHour);
        }
    }, [isEdit, newsDate, newsHour]);


    const createNewNews = async () => {
        try {
            const apiName = 'Atenida';
            const path = '/news';
            const myInit = {
                body: { 'title' : title,
                        'category' : category,
                        'description' : description,
                        'content' : content,
                        'image' : imageFile ? await readFileAsBase64(imageFile) : null,
                }, 
                headers: {} // OPTIONAL
            }
            console.log(imageFile);
        await API.post(apiName, path, myInit);
        setMessage('Confirmation successful!');
    
        } catch (error) {
          console.log('Error creating news', error);
          setMessage('Send failed ' + error);
        }
        setOpen(true);
      };

      const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setImageFile(file);
      };

      const readFileAsBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });

        const handleUpdateNews = async () => {
          try {
            const apiName = 'Atenida';
            const path = `/news?date=${newsDate}&hour=${newsHour}`; // Update the path with the newsId
            const myInit = {
              body: {
                title,
                category,
                description,
                content
              },
              headers: {} // OPTIONAL
            };
            await API.put(apiName, path, myInit);
            setMessage('Update successful!');
          } catch (error) {
            console.log('Error updating news', error);
            setMessage('Update failed ' + error);
          }
          setOpen(true);
        };
    
  return (
    <>
        <InfoContainer>
            <InfoWrapper>
            <ThemeProvider theme={theme}>
            <ModalItems>   
            <TextField id="outlined-basic" label="Kategoria" variant="outlined" fullWidth value={category} 
                    onChange={(event) => {setCategory(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            <ModalItems>   
            <TextField id="outlined-basic" label="Tytuł" variant="outlined" fullWidth value={title} 
                    onChange={(event) => {setTitle(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            <ModalItems>   
            <TextField id="outlined-basic" label="Opis" variant="outlined" fullWidth value={description} 
                    onChange={(event) => {setDescription(event.target.value);}} multiline
                    sx={{
                      '.MuiInputBase-input': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                      '.MuiInputLabel-root': { xs : { fontSize: '0.75rem' }, md: { fontSize: '1rem' } },
                    }} />
            </ModalItems>
            </ThemeProvider>
            {isEdit ? null : <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginBottom: '1rem' }}/>}
            
            <ReactQuill value={content} onChange={setContent} />
            <BtnWrap>
            <Button onClick={isEdit ? handleUpdateNews : createNewNews}>
              {isEdit ? 'UPDATE' : 'SEND'}
            </Button>
            </BtnWrap>
            <Collapse in={open}>
            <Alert
              action={
                  <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                      setOpen(false);
                  }}
                  >
                  <CloseIcon fontSize="inherit" />
                  </IconButton>
              }
              sx={{ mb: 2 }}
              >
              {message}
          </Alert>
        </Collapse>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default NewsEdit