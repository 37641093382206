import React, {useState} from 'react'
import Image from '../../images/bg.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroPP, HeroBtnWrapper } from './HeroElements'
import { Button } from '../ButtonElements'
import { animateScroll as scroll } from 'react-scroll';


const Hero2 = () => {
    const toggleHome = () => {
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }


  return (
    <>
        <HeroContainer id='home'>
            <HeroBg>
                 <VideoBg src={Image} autoPlay loop muted />
            </HeroBg>
            <HeroContent>
                <HeroH1>Jeśli zadajesz sobie często pytania:</HeroH1>
                <HeroP>- Jak przestać rozpamiętywać przeszłość?</HeroP>
<HeroP>- Jak ogarnąć ten chaos w moim życiu?</HeroP>
<HeroP>- Jak przestać żałować, że się czegoś nie zrobiło?</HeroP>
<HeroP>- Jak wyjść z impasu i ruszyć do przodu?</HeroP>
<HeroP>- Jak przestać się obwiniać?</HeroP>
<HeroP>- Jak wybaczyć sobie i innym?</HeroP>
<HeroP>- W jaki sposób podjąć działanie?</HeroP>
<HeroP>- Jak znaleźć w sobie motywację do działania?</HeroP>
<HeroP>- Jak przestać się bać pokazania siebie w pełnym potencjale?</HeroP>
<HeroP>- Jak przestać nadmiernie kontrolować i zacząć ufać?</HeroP>
<HeroP>- Jaki mam w sobie potencjał, co jest moją pasją?</HeroP>
<HeroP>- Jak poczuć się bezpiecznie?</HeroP>
<HeroP>- Jak zacząć w końcu żyć swoim wymarzonym życiem?</HeroP>
<HeroP>- Jak przestać odczuwać dojmującą samotność?</HeroP>
<HeroP>- Jak ponownie otworzyć się na nową relację?</HeroP>
<HeroPP>Zapraszam Cię do pracy ze mną.</HeroPP>
                
                <HeroBtnWrapper>
                    <Button to="/booking" onClick={toggleHome} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' big='true'>
                    Umów się na spotkanie
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    </>
  )
}

export default Hero2