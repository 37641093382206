import React, { useState } from 'react';

import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Confapi from '../components/Confapi';

import { 
    Wrapper, 
    RowWrapper, 
    ViewWrapper, 
} from '../components/Booking/BookingElements'

const Confirm = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }
  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Wrapper>
        <RowWrapper>
            <ViewWrapper>
                <Confapi />
            </ViewWrapper>
        </RowWrapper>
    </Wrapper>
    <Footer />
    </>
  );
};

export default Confirm;