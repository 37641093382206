import React, {useState} from 'react'
import Image from '../../images/bg.mp4'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper , Linka} from './HeroElements'
import { ButtonOut } from '../ButtonElements'


const Hero3 = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    const Click = () => {
        navigator.clipboard.writeText('coaching@atenida.pl');
    }


  return (
    <>
        <HeroContainer id='home'>
            <HeroBg>
                 <VideoBg src={Image} autoPlay loop muted />
            </HeroBg>
            <HeroContent>
                {/* <Logo src={LogoImage} /> */}
                <HeroH1>Skontaktuj się ze mną</HeroH1>
                <HeroP>
                Potrzebujesz wsparcia? Napisz do mnie na: <Linka onClick={Click}>coaching@atenida.pl</Linka>
                </HeroP>
                <HeroBtnWrapper>
                    <ButtonOut href="mailto:coaching@atenida.pl" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' big='true'>
                    Napisz do mnie
                    </ButtonOut>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    </>
  )
}

export default Hero3