import home1Image from '../../images/home1.jpg';
import ltc1Image from '../../images/ltc1.jpg';
import ate1Image from '../../images/ate1.jpg';
import hist1Image from '../../images/hist1.jpg';
import rez1Image from '../../images/rez1.jpg';
import rand1Image from '../../images/rand1.jpg';
import rand2Image from '../../images/rand2.jpg';

export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'KIM JEST ANETA WOJCIECHOWSKA?',
    headline: 'O mnie',
    description: 'Nazywam się Atenida, jestem life-transition coachem i chcę pomóc Ci przejść przez kluczowe i często trudne momenty w Twoim życiu, aby wydobyć Twój potencjał, tak abyś mógł/mogła na nowo czerpać radość i satysfakcję ze swojego życia.',
    buttonLabel: 'Dowiedz się więcej',
    imgStart: false,
    img: home1Image,
    alt: 'Atenida',
    dark: false,
    primary: false,
    darkText: false,
    button: true,
    link: '/omnie',
    blank: false,
    round: false
}

export const homeObjThree = {
    id: 'lifecoach',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'KIM JEST LIFE-TRANSITION COACH?',
    headline: 'Moja specjalizacja',
    description: 'Life-transition coach (pol. trener/przewodnik przejścia życiowego) to specjalista, który pomaga ludziom w przejściach i zmianach życiowych, takich jak zmiana pracy, rozwód, przeprowadzka, śmierć bliskiej osoby, emigracja, a także zmiany związane z etapami życiowymi, takimi jak wejście w dorosłość, ślub, narodziny dziecka, czy zmiana roli życiowej...',
    buttonLabel: 'Czytaj dalej',
    imgStart: true,
    img: rand1Image,
    alt: 'Atenida',
    dark: true,
    primary: true,
    darkText: true,
    button: true,
    link: '/life-transition-coach',
    blank: false,
    round: false
}

export const homeObjFour = {
    id: 'help',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'W CZYM MOGĘ CI POMÓC?',
    description: `Mój sposób pracy polega na dopasowaniu do klienta, koncentracji na jego potrzebach i celach. Razem opracujemy plan działań, który pomoże Ci przetrwać trudne chwile i osiągnąć pozytywne zmiany w Twoim życiu.`,
    buttonLabel: 'Sprawdź jak pracuję',
    imgStart: false,
    img: require('../../images/svg17.svg').default,
    alt: 'Atenida',
    dark: false,
    primary: false,
    darkText: false,
    button: true,
    link: '/styl-pracy',
    blank: false,
    round: false
}

export const homeObjTwo = {
    id: 'home2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Umów się na wiztytę',
    headline: 'Rezerwacja wizyty',
    description: 'Sprawdź terminarz i umów się na spotkanie. Minimum formalności i szybkie potwierdzenie rezerwacji.',
    buttonLabel: 'Terminarz',
    imgStart: false,
    img: rez1Image,
    alt: 'Terminarz',
    dark: false,
    primary: false,
    darkText: false,
    button: true,
    link: '/booking#calendar',
    blank: false,
    round: false
}

///oferta

export const Page2ObjOne = {
    id: 'wsparcie',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Wsparcie indywidualne',
    headline: 'Jak przebiegają konsultacje?',
    description: `Sesje indywidualne to niezwykle ważny element mojej pracy jako life-transition coacha. To w trakcie sesji indywidualnych mogę zwrócić pełną uwagę na Twoje potrzeby i cele oraz skupić się na tym, co jest dla Ciebie ważne. 
    <br><br>
    Sesje indywidualne to czas, który poświęcamy na rozmowę o Twoim życiu, wyzwaniach i przeszkodach, z którymi się borykasz. W trakcie sesji będziemy rozmawiać o Twoich marzeniach i celach, a także o tym, co może Cię hamować w ich osiągnięciu. Razem będziemy analizować Twoje postępy i strategie, które wypracowaliśmy, aby osiągnąć pozytywne zmiany w Twoim życiu. 
    <br><br>
    W czasie sesji indywidualnych wykorzystuję różne narzędzia, takie jak testy osobowości, wizualizacje, medytacje i ćwiczenia, które pomagają w rozwoju osobistym i osiąganiu pozytywnych zmian w życiu. Wszystkie te narzędzia pomagają mi lepiej poznać Twoje potrzeby i cele oraz dostosować nasze sesje do Twoich indywidualnych potrzeb. 
    <br><br>
    Sesje indywidualne odbywają się zazwyczaj raz w tygodniu lub raz na dwa tygodnie, w zależności od Twoich potrzeb. Są one przeprowadzane online na platformie zoom i trwają około godziny. Jeśli czujesz, że potrzebujesz wsparcia w przejściach życiowych i osiągnięciu pozytywnych zmian w swoim życiu, to sesje indywidualne z life-transition coachem mogą być dla Ciebie doskonałym rozwiązaniem. Napisz do mnie, aby umówić się na indywidualną sesję i zacząć pracę nad swoim życiem już dziś!`,
    buttonLabel: 'Umów się!',
    imgStart: true,
    img: require('../../images/svg7.svg').default,
    alt: 'support',
    dark: false,
    primary: false,
    darkText: false,
    button: true,
    link: '/booking',
    blank: false,
    round: false
}

export const Page2ObjTwo = {
    id: 'przystan',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Bezpieczna przestrzeń',
    headline: 'Wsparcie emocjonalne',
    description: `
    Przejścia w życiu są nieuniknione, a wiele z nich stanowi dla nas duże wyzwanie. Często stawiają nas przed dylematami, które musimy rozwiązać, podejmować nowe decyzje lub zmieniać nasze podejście do życia. W takich momentach nieocenione jest wsparcie emocjonalne ze strony bliskich, przyjaciół lub profesjonalistów.
    <br><br>
    Wsparcie emocjonalne jest istotne w kluczowych momentach przejścia w życiu, ponieważ pomaga nam radzić sobie z trudnymi emocjami, jakie mogą towarzyszyć takim sytuacjom. Kiedy czujemy się zestresowani, zdezorientowani lub samotni, obecność kogoś, kto nas słucha i jest z nami, może mieć ogromny wpływ na nasze samopoczucie.
    <br><br>
    Szczególnie w przypadku trudnych przejść, takich jak utrata bliskiej osoby, rozwód, zmiana pracy czy przeprowadzka, wsparcie emocjonalne jest kluczowe dla naszego dobrostanu psychicznego. Wsparcie emocjonalne jest ważne również w przypadku pozytywnych przejść w życiu, takich jak np. ślub, narodziny dziecka czy awans zawodowy. Choć są to pozytywne wydarzenia, mogą one również wiązać się z dużą zmianą w życiu i potrzebą dostosowania się do nowych warunków.
    <br><br>
    Wsparcie emocjonalne może wtedy pomóc nam cieszyć się z tych wydarzeń i przystosować się do nowej sytuacji.
    <br><br>
    Podsumowując, wsparcie emocjonalne jest niezwykle istotne w kluczowych momentach przejścia w życiu człowieka. Pomaga nam radzić sobie z trudnymi emocjami, zrozumieć swoje potrzeby i podjąć odpowiednie kroki, aby osiągnąć pozytywne zmiany w naszym życiu.
    `,
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/svg4.svg').default,
    alt: 'safe',
    dark: true,
    primary: false,
    darkText: true,
    button: false,
    link: '',
    blank: false,
    round: false
}

// export const Page2ObjThree = {
//     id: 'wsparcieInd',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Wsparcie indywidualne',
//     headline: 'Jak przebiegają konsultacje?',
//     description: 'Podczas indywidualnych konsultacji skupiamy się na Twoich potrzebach i trudnościach związanych z żałobą i stratą. Pracujemy razem, by znaleźć sposoby na radzenie sobie z bólem i znaleźć nowy sens w życiu po stracie. Dowiedz się więcej i umów się na spotkanie.',
//     buttonLabel: '',
//     imgStart: true,
//     img: require('../../images/svg7.svg').default,
//     alt: 'support',
//     dark: false,
//     primary: true,
//     darkText: true,
//     button: true,
//     link: '',
//     blank: false
// }


//kontakt

export const kontaktObjOne = {
    id: 'kontakt',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Zapraszam do kontaktu',
    headline: 'Potrzebujesz wsparcia?',
    description: 'Jeśli potrzebujesz wsparcia w trudnym dla siebie czasie, umów się na konsultację. Jako coach, oferuję Ci pomoc i wsparcie emocjonalne. Pomogę Ci ruszyć do przodu, w kierunku życia. Bez ciągłego rozpamiętywania przeszłości i myślenia o tym, co można było zrobić inaczej. Dzięki mojej pomocy zauważysz również swoje mocne strony, przez co łatwiej będzie Ci zacząć żyć w radości i poczuciu satysfakcji.',
    buttonLabel: 'Umów się!',
    imgStart: true,
    img: require('../../images/svg8.svg').default,
    alt: 'rezerwacja',
    dark: false,
    primary: true,
    darkText: true,
    button: true,
    link: '/booking',
    blank: false,
    round: false
}

export const kontaktObjTwo = {
    id: 'kontakt2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Dołącz do grupy na Facebooku',
    headline: 'Wsparcie w grupie',
    description: 'Dołącz do grupy na Facebooku, gdzie możesz porozmawiać z innymi osobami przeżywającymi momenty przejścia w swoim życiu. Wspólnie możemy podzielić się tam swoimi doświadczeniami i pomóc sobie nawzajem. Często o klientów na sesjach słyszę, że czują się w tych historiach samotni - nie musi tak być. Nie jesteś sam(a).',
    buttonLabel: 'Grupa na Facebooku',
    imgStart: false,
    img: require('../../images/svg6.svg').default,
    alt: 'facebook',
    dark: false,
    primary: false,
    darkText: false,
    button: true,
    link: 'https://www.facebook.com/groups/1832360620466564/',
    blank: true,
    round: false
}

export const kontaktObjThree = {
    id: 'kontakt3',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Sprawdź mój kanał na YouTube',
    headline: 'Materiały wideo',
    description: 'Zapraszam do obejrzenia materiałów wideo na moim kanale na YouTube. W nich opowiemo moich doświadczeniach i podzielę się wiedzą na temat (często trudnych) momentów przejściowych w życiu.',
    buttonLabel: 'Kanał na Youtube',
    imgStart: true,
    img: require('../../images/svg12.svg').default,
    alt: 'youtube',
    dark: false,
    primary: true,
    darkText: true,
    button: true,
    link: 'https://youtube.com/@atenida',
    blank: true,
    round: false
}


///ABOUT

export const aboutObjOne = {
    id: 'about1',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'KIM JEST ATENIDA (ANETA WOJCIECHOWSKA)?',
    headline: 'O mnie',
    description: `
    Nazywam się Atenida, jestem life-transition coachem i chcę pomóc Ci przejść przez kluczowe i często trudne momenty w Twoim życiu, aby wydobyć Twój potencjał, tak abyś mógł/mogła na nowo czerpać radość o satysfakcję ze swojego życia.
    <br><br>

    Większość z nas w swoim życiu doświadcza momentów, które są pełne zmian, niewiadomych i niepewności. Wiem, jak to jest, ponieważ sama przeszłam przez wiele takich momentów.
    <br><br>

    Zawsze czułam się trochę inna, nie pasująca do żadnego środowiska. Mój proces wejścia w dorosłość był pełen wyzwań, buntu, odrzucenia, szukania swojej ścieżki i pasji. Potem przyszedł czas na ślub, zmiany pracy i budowanie niezależności finansowej - w tym zakup mojego pierwszego mieszkania i kolejnych mieszkań inwestycyjnych. Jednakże, doświadczyłam również trudnych momentów w praktycznie każdym obszarze, w którym mogę pomóc również Tobie.
    
    <br><br>

    
    `,
    buttonLabel: '',
    imgStart: false,
    img: ate1Image,
    alt: 'omnie',
    dark: true,
    primary: false,
    darkText: false,
    button: false,
    link: '',
    blank: false,
    round: false
}

export const aboutObjTwo = {
    id: 'about2',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'KIM JEST ATENIDA (ANETA WOJCIECHOWSKA)?',
    headline: 'Moja historia',
    description: `To właśnie te doświadczenia zainspirowały mnie do rozpoczęcia pracy jako coach. Również moja historia rodowa zawsze oscylowała koło tematów związanych ze stratą. Od urodzenia miałam styczność z ludźmi przeżywającymi trudne momenty, ponieważ moi rodzice prowadzą zakład kamieniarski specjalizujący się w nagrobkach. Sama odkąd tylko mogłam, pracowałam z ludźmi, pomagając im podjąć jak najlepszy wybór dla upamiętnienia swoich bliskich.Dzięki tej pracy nauczyłam się niezwykłej delikatności, a także zrozumienia dla osób w takich momentach.
    To była najlepsza szkoła. Teraz przekładam tamto doświadczenie na efektowne i odpowiednie wsparcie podczas prowadzonych przeze mnie sesji coachingowych. To nie koniec historii mojego rodu, która wpisała się w moją osobistą historię i potwierdziła mi słuszność wyboru mojej ścieżki życiowej. O tym chętnie opowiem więcej na moim kanale na YouTube.
    
    <br><br>
    Najbliżsi znajomi często się śmieją i mówią, że w swoim młodym życiu przeżyłam „tych żyć” już conajmniej kilka. Bo tak intensywnych zmian i wyzwań podejmowałam się przez całą swoją historię. Szukając w życiu siebie, zmieniałam ścieżki kariery wielokrotnie: pracując w firmie rodzinnej, w hotelu, w dużej firmie korporacyjnej, jako agent nieruchomości, jako manager dobrze prosperującego, innowacyjnego startup’u.
    `,
    buttonLabel: '',
    imgStart: true,
    img: hist1Image,
    alt: 'misja',
    dark: false,
    primary: true,
    darkText: true,
    button: false,
    link: '',
    blank: false,
    round: false
}

export const aboutObjThree = {
    id: 'about3',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'KIM JEST ATENIDA (ANETA WOJCIECHOWSKA)?',
    headline: 'Moje doświadczenie',
    description: `
    Skończyłam uwielbiane przeze mnie studia magisterskie na Uniwersytecie Wrocławskimi, na kierunku, który również pomaga mi w pracy coacha - „Communication Design”, dzięki nim znam najważniejsze założenia i narzędzia potrzebne w skutecznym komunikowaniu się z drugim człowiekiem. Jestem również pod opieką mentoringową u Maya Ori. Ukończyłam pod jej okiem Excellence Academy dla Świadomych Kobiet, program „Miłość Zamiast Wojny” i wiele innych, a także Akademię Coachów by Maya Ori, uzyskując tym samym certyfikat. 
Biorę udział w licznych szkoleniach, jak np. „Praca z osobą w żałobie: Nowe Perspektywy” organizowanym przez Instytut Dobrej Śmierci. Pracuję również cały czas nad sobą i kształcę się w wielu dziedzinach. Cyklicznie wydaje na swoje szkolenia około 40 tyś. zł/ rok ( w zeszłym roku ponad 160 tyś. złotych), bo uważam, że nie ma nic lepszego niż inwestycja w siebie.
<br><br>

Teraz moje życie jest takim, jakiego od zawsze pragnęłam. Bez zahamowań, bez lęku, bez przejmowania się opinią innych, bez ukrywania i hamowania własnego potencjału, bez roztrząsania przeszłości i bez oczekiwań wobec świata zewnętrznego. 
Czuję, że przynależę, że jestem niezależna i w pełni swojej kobiecości. Skupiam się na pozytywach, na tu i teraz. Kreuję swoją rzeczywistość, zgodnie z moimi zasadami i moimi pragnieniami. W mocy i sprawczości. 
Spełniam się twórczo i kreatywnie: tworzę biżuterię, śpiewam operowo, tańczę, piszę. Podróżuję po całym świecie. Jestem przynajmniej raz na trzy miesiąca za granicą, by doświadczać innych kultur, podziwiać nowe krajobrazy. 
Mam wymarzony związek pełen miłości i harmonijnego partnerstwa. Odnalazłam swoje pasje i ukryte talenty. Jestem wolna finansowo i zarabiam w wielu obszarach. 
Między innymi inwestując pasywnie w nieruchomości, tworząc biżuterię, czy pracując jako coach - to wszystko są obszary, które uwielbiam i dzięki znalezieniu, tego, co mi się podoba - śmiało mogę stwierdzić, że jeśli robisz, to co lubisz, to żaden Twój dzień, nie będzie ciężką pracą.
    
    `,
    buttonLabel: '',
    imgStart: false,
    img: require('../../images/svg18.svg').default,
    alt: 'omnie',
    dark: true,
    primary: false,
    darkText: false,
    button: false,
    link: '',
    blank: false,
    round: false
}

export const aboutObjFour = {
    id: 'about4',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'KIM JEST ATENIDA (ANETA WOJCIECHOWSKA)?',
    headline: 'Nasza droga',
    description: `
    Pamiętam jak sama się czułam na początku tej drogi i w tych trudnych chwilach, dlatego chcę pomóc ludziom, którzy są w takich momentach swojego życia, którzy potrzebują wsparcia i kogoś, kto ich zrozumie.<br>
Jako life-transition coach pomagam moim klientom zrozumieć swoje potrzeby i cele, a także nauczyć ich, jak radzić sobie z trudnymi emocjami, które towarzyszą procesowi przemiany.
Skupiam się na tym, aby pomóc im osiągnąć sukces, odnaleźć swoją drogę i osiągnąć pełnię swojego potencjału i życia w radości.

<br><br>
Wierzę, że każdy z nas ma w sobie moc potrzebną do zmiany i że każdy z nas może osiągnąć swoje cele. Dlatego staram się zapewnić swoim klientom pełne wsparcie, a także poufność w procesie coachingu, aby mogli czuć się swobodnie i bezpiecznie.
<br><br>

Jeśli chcesz przejść przez kluczowe momenty w swoim życiu, masz dosyć rozpamiętywania przeszłości, siedzenia w cieniu i chcesz spełnić swoje marzenia, osiągnąć swoje cele, to chętnie Ci pomogę. 
Skontaktuj się ze mną, abyśmy mogli omówić, jak mogę Ci pomóc w Twoim procesie przemiany.
    
    
    `,
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/svg19.svg').default,
    alt: 'misja',
    dark: false,
    primary: true,
    darkText: true,
    button: false,
    link: '',
    blank: false,
    round: false
}

export const newsObjOne = {
    id: 'news1',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Artykuł',
    headline: 'Nagłówek artykułu',
    description: 'Lorem ipsum, dolor sit ame consect etur adipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    buttonLabel: 'Czytaj więcej',
    imgStart: false,
    img: require('../../images/svg3.svg').default,
    alt: 'news',
    dark: true,
    primary: false,
    darkText: false,
    button: false,
    link: '',
    blank: false,
    round: false
}

///COACH

export const coachObjOne = {
    id: 'coach1',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'KIM JEST LIFE-TRANSITION COACH?',
    headline: 'Przewodnik',
    description: `
    Life-transition coach (pol. trener/przewodnik przejścia życiowego) to specjalista, który pomaga
    ludziom w przejściach i zmianach życiowych, takich jak zmiana pracy, rozwód, przeprowadzka,
    śmierć bliskiej osoby, emigracja, a także zmiany związane z etapami życiowymi, takimi jak wejście
    w dorosłość, ślub, narodziny dziecka, czy zmiana roli życiowej.
    <br><br>

    Zadaniem life-transition coacha jest pomóc klientowi w przejściu przez trudne momenty i pomóc
mu osiągnąć cele, które chce osiągnąć w nowej sytuacji. Coach pomaga klientowi w identyfikacji
przeszkód, które stoją na drodze do sukcesu, a także w opracowaniu strategii, które pomogą mu
osiągnąć pożądane wyniki.
<br><br>
    `,
    buttonLabel: '',
    imgStart: false,
    img: ltc1Image,
    alt: 'omnie',
    dark: true,
    primary: false,
    darkText: false,
    button: false,
    link: '',
    blank: false,
    round: false
}

export const coachObjTwo = {
    id: 'coach2',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'KIM JEST LIFE-TRANSITION COACH?',
    headline: 'Sposoby pracy',
    description: `  
Praca z life-transition coachem obejmuje zazwyczaj wiele sesji indywidualnych, w których coach
pomaga klientowi w zrozumieniu jego celów i potrzeb, a także w opracowaniu planu działań, które
pomogą mu osiągnąć sukces. Coach może również wykorzystać różne narzędzia, takie jak testy
osobowości, wizualizacje, medytacje i ćwiczenia, które pomagają w rozwoju osobistym
i osiąganiu pozytywnych zmian w życiu.
    <br><br>
    Ważną rolą life-transition coacha jest także pomaganie klientom
    w utrzymaniu motywacji i zaangażowania w osiąganiu celów, a także w radzeniu sobie
    z trudnościami i przeciwnościami, które mogą pojawić się w trakcie procesu zmiany. Coach jest
    wsparciem dla klienta w trudnych chwilach, a także w osiąganiu sukcesów
    i realizowaniu swoich marzeń.
    
    `,
    buttonLabel: '',
    imgStart: true,
    img: require('../../images/svg3.svg').default,
    alt: 'misja',
    dark: false,
    primary: true,
    darkText: true,
    button: false,
    link: '',
    blank: false,
    round: false
}


///HELP

export const helpObjOne = {
    id: 'help1',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Styl pracy',
    headline: 'W CZYM MOGĘ CI POMÓC?',
    description: `  
    Cześć! Nazywam się Atenida i jestem life-transition coachem. Jestem tutaj, aby Ci pomóc
    w trudnych momentach i przejściach
    w życiu. Moja praca polega na pomaganiu ludziom w osiąganiu ich celów, radzeniu sobie
    z przeciwnościami i realizowaniu swojego pełnego potencjału.
    <br><br>
    Mój styl pracy opiera się na indywidualnym podejściu do klienta
    i skupieniu się na jego potrzebach i celach. Razem z Tobą stworzymy plan działań, który pomoże
    Ci przejść przez trudne momenty i osiągnąć pozytywne zmiany w Twoim życiu.
    Wspieram ludzi w różnych przejściach życiowych, takich jak zmiana pracy, rozwód, rozstanie,
    śmierć kogoś bliskiego, przeprowadzka, wejście w dorosłość, narodziny dziecka oraz inne zmiany
    związane z etapami życia czy zmianami roli życiowej. <br><br> 
    
    Moim zadaniem jest pomaganie klientowi
    w identyfikacji przeszkód, które stoją na drodze do sukcesu, a także w opracowaniu strategii,
    które pomogą osiągnąć pożądane wyniki.
    
    `,
    buttonLabel: '',
    imgStart: true,
    img: rand2Image,
    alt: 'misja',
    dark: false,
    primary: true,
    darkText: true,
    button: false,
    link: '',
    blank: false,
    round: false
}


export const helpObjTwo = {
    id: 'help2',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'KIM JEST LIFE-TRANSITION COACH?',
    headline: 'Przewodnik',
    description: `
    Jako life-transition coach, pomagam klientom w osiąganiu równowagi i harmonii w życiu, a także
w utrzymywaniu motywacji
i zaangażowania w realizacji ich celów. Razem przejdziemy przez trudne momenty, a także
wspólnie będziemy świętować Twoje sukcesy i osiągnięcia.
<br><br>
Jeśli chcesz uzyskać więcej informacji na temat mojej pracy jako life-transition coacha,
zapraszam do kontaktu i umówienia się na indywidualną sesję odkrywczą. Razem stworzymy
plan, który pomoże Ci osiągnąć to, czego pragniesz w życiu.<br><br>
Napisz na coaching@atenida.pl lub skorzystaj z formularza kontaktowego na stronie.
    `,
    buttonLabel: 'Kontakt',
    imgStart: false,
    img: require('../../images/svg21.svg').default,
    alt: 'help',
    dark: false,
    primary: false,
    darkText: false,
    button: true,
    link: '/kontakt',
    blank: false,
    round: false
}