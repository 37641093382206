import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import { Button } from '../components/ButtonElements'
import { InfoContainer, InfoWrapper } from '../components/InfoSection/InfoElements'

const AdminPage = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    {/* <Hero3 /> */}
    <InfoContainer>
      <InfoWrapper>
    <Button to='/timeslots'>EDYCJA TERMINÓW </Button><br></br>
    <Button to='/newsedit'>DODAWANIE WPISÓW</Button><br></br>
    <Button to='/offerEdit'>DODAWANIE OFERTY</Button><br></br>
    <Button to='/news'>EDYCJA WPISÓW</Button><br></br>
    <Button to='/oferta/all'>EDYCJA OFERTY</Button><br></br>
    </InfoWrapper>
    </InfoContainer>
    <Footer />
    
    </>
  )
}

export default AdminPage