import React, {useState} from 'react'
import Image from '../../images/bg.mp4'
import { HeroContainer, HeroBg, VideoBg, Logo, HeroContent, HeroH1, HeroP, HeroBtnWrapper } from './HeroElements'
import { Button } from '../ButtonElements'
import LogoImage from '../../images/ATENIDA-logo2-19.svg'
import { animateScroll as scroll } from 'react-scroll';


const HeroSection = () => {
    const toggleHome = () => {
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }
    
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    // const Click = () => {
    //     navigator.clipboard.writeText('coaching@atenida.pl');
    // }


  return (
    <>
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg src={Image} autoPlay loop muted />
            </HeroBg>
            <HeroContent>
                <Logo src={LogoImage} />
                <HeroH1>Pomoc w przejściu przez przełomowe i wymagające momenty w Twoim życiu</HeroH1>
                <HeroP>
                Też tam byłam. Znam Twoją historię. Nie musisz być w tym sam(a).
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="/konsultacje" onClick={toggleHome} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true' big='true'>
                    Rozpocznijmy pracę
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    </>
  )
}

export default HeroSection