import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import SingleArticle from '../components/SingleArticle'
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';


const FullArticle = () => {
    const { date, hour } = useParams();
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SingleArticle date={date} hour={hour} />
            <Footer />
        </>
    )
}

export default FullArticle