import { useState, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

export function RequireAuth({ children }) {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export function RequireAdmin({ children }) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function getGroups() {
      const authUser = await Auth.currentAuthenticatedUser();
      const groups = authUser.signInUserSession.accessToken.payload["cognito:groups"];
      if (groups === undefined) {
        return false;
      }
      if (groups.indexOf('admin') !== -1) {
        setIsAdmin(true);
        return true;
      }
      return false;
    }
    getGroups();
  }, []);
  
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location, search: location.search }} replace />;
  }
  if (!isAdmin) {
    return (<div>Not Authorized</div>);
  }
  return children;
}

export function RequireAdminComp({ children }) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function checkAdmin() {
      try {
        const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        if (authUser) {
          const groups = authUser.signInUserSession.accessToken.payload['cognito:groups'];
          if (groups && groups.includes('admin')) {
            setIsAdmin(true);
          }
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        setIsAdmin(false);
      }
    }

    checkAdmin();
  }, []);

  if (!isAdmin) {
    return null;
  }

  return children;
}