import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import  {InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Subtitle, Column2, ImgWrap, Img } from '../components/OpinieElements'

import img1 from '../images/img2.svg';

const Opinie = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }


    const opinieArray = [
      { id: 1, left: true, text: `„Bardzo dziękuję za moją sesję <3 Dała mi dużo spokoju, pomogła lepiej zrozumieć tematy
      związane z duchowością. Aneta łączy różne wątki, podchodzi do problemów kompleksowo i
      daje mnóstwo motywacji, by wprowadzić do swojego życia pozytywne zmiany.” - Emilia 34 l.` },
      { id: 2, left: false, text: `„Bardzo dziękuję za naszą sesję, czułam się na niej swobodnie i zaopiekowana. Była
      zaskakująca, ze łzami, śmiechem, betonem 😂 . Jesteś wspaniałą przewodniczką w podróży
      do życia życiem. Lekkość, która pojawiła stępo procesie cały czas mi towarzyszy i jest to
      niesamowite po tak długim czasie. Z mamą kojarzą mi się kwitnące kwiaty, chce się nimi
      otaczać. Jestem gotowa na nowe i chętnie będę Cię polecać. Z miłością.” - Kasia 44 l.` },
      { id: 3, left: true,text: `„Profesjonalne, rzetelne poprowadzenie sesji. W atmosferze życzliwości. Aneta pomimo, że
      miała przygotowane pytania do sesji, to podążyła za procesem, co bardzo było dla mnie
      wartościowe i uwalniające. Polecam sesje z Anetą, jeżeli Twoim tematem jest strata i żałoba.
      Czułam się na sesji zaopiekowana” - Ania 42 l.` },
      { id: 4, left: false, text: `„Dzięki atmosferze, którą stworzyłaś czułam się komfortowo. Byłam w stanie opowiadać o
      tym, co mnie spotkało. Zadawałaś pytania, które wymagały ode mnie zatrzymania się,
      zastanowienia. Szczególnie jedno pytanie w kontekście przyszłości, zostało ze mną: Co
      musiałoby się zadziać, abym czuła się bezpiecznie? Jestem wdzięczna za ten czas z Tobą.” -
      Wiesława 49 l.` },
      { id: 5, left: true, text: `„Anetko, jestem po sesji z Tobą. Mądre, pouczające, konkretne spotkanie… Jakbyś w oczach
      miała coś, co widzi w moim sercu. Byłam rozluźniona i rozmawiałam jak z przyjacielem, który
      chce mi pomóc. Dziękuję za proces, za cenne wskazówki i zadanie domowe. Gorąco polecam
      Anetkę. Bardzo profesjonalny Coach. Bardzo, bardzo dziękuję.” - Bożena 54 l.` },
    ];  

  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />

    {opinieArray.map(opinia => (

    <InfoContainer lightBg={true} key={opinia.id}>
      <InfoWrapper>
        <InfoRow imgStart={opinia.left}>
          <Column1>
            <TextWrapper>
              <TopLine>Opinie klientów</TopLine>
              <Subtitle darkText={true} dangerouslySetInnerHTML={{ __html: opinia.text }}></Subtitle>
            </TextWrapper>
          </Column1>
          <Column2>
          <ImgWrap>

            <Img src={img1}></Img>

          </ImgWrap>

          </Column2>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>

    ))}

    <Footer />
    </>
  )
}

export default Opinie