import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 870px;
    position: relative;
    z-index: 1;
    min-height: 592px;
    top: 0;
    background: linear-gradient(
        108deg,
        rgba(220, 213, 201, 1) 0%,
        rgba(190, 179, 160, 1) 100%
    );
`;

export const ColumnWrapper = styled.div`
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    min-width: 440px;
    min-height: 400px;
    align-items: start;

    @media screen and (max-width: 868px) {
        min-width: 350px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px;
    }
`;

export const ViewWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 868px) {
        flex-direction: column;
    }
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 868px) {
        width: 100%;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    color: #000;
    margin-bottom: 12px;
    top: 0;
    justify-content: center;
    color: #4E4D4A;
`;

export const TitleWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const AddButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 14px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  min-width: 1000px;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  wdith: 80%;
  margin-top: 80px;

    @media screen and (max-width: 1000px) {
        width: 100%;
        max-width: 100%;
        min-width: 300px;
        margin-top: 120px;
    }
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
  font-size: 32px;
  color: #465D56;

    &:hover {
        color: #A1AC9E;
    }
`;

export const ModelText = styled.p`
    font-size: 18px;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 2px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Appointments = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 5px;
  border-bottom: 1px solid #A1AC9E;
`;

export const AppointmentTime = styled.div`
  font-weight: 700;
  align-items: center;
  width: 120px;
  color: #4E4D4A;
`;

export const AppointmentName = styled.div`
   margin: 0 2rem;
   color: #4E4D4A;
   align-items: center;
   justify-content: center;
`;

export const DeleteButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const DeleteButton = styled.button`
    border-radius: 50px;
    background: #A1AC9E;
    white-space: nowrap;
    padding: 8px 12px;
    color: #fff;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    //align item to the right of the parent
    justify-content: flex-end;
    align-items: center;
    transition: all 0.75s ease-in-out;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        transition: all 0.5s ease-in-out;
        color: #fff;
        background: #465D56;
    }
`;

export const ModalItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
`;

export const NoItems = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #465D56;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const NoAppointment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px 5px;
  border-bottom: 1px solid #A1AC9E;
`;











