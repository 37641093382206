import React, { useState, useEffect }from 'react'
import { ButtonS } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Column2, ImgWrap, Img } from './HelpElements'
import { animateScroll as scroll } from 'react-scroll'

import styled from 'styled-components';

const ScrollText = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? 'translateX(0)' : 'translateX(-100px)'};
  transition: all 2s ease-in-out;
`;

const ScrollText2 = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? 'translateX(0)' : 'translateX(100px)'};
  transition: all 2s ease-in-out;
`;

const idArray = [
    { id: 1, isVisible: false },
    { id: 2, isVisible: false },
    { id: 3, isVisible: false },
    { id: 4, isVisible: false },
    { id: 5, isVisible: false },
  ];    

const Help = ({lightBg, id, imgStart, topLine, lightText, darkText, headline, buttonLabel, img, alt, primary, dark, dark2, button, link, blank}) => {
    const toggleHome = () => {
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }
    const [Visibility, setVisibility] = useState(idArray);

    useEffect(() => {
        const handleScroll = () => {
        const screenHeight = window.innerHeight;
        const newVisibility = Visibility.map(item => {
            const itemPosition = document
            .getElementById(`fly-${id}${item.id}`)
            .getBoundingClientRect().top;
            if (itemPosition < screenHeight * 0.8) {
            return { ...item, isVisible: true };
            }
            return item;
        });
        setVisibility(newVisibility);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [Visibility]);
  return (
    <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <ScrollText className="fly" isVisible={Visibility[0].isVisible} id={`fly-${id}${Visibility[0].id}`}>
                            <TopLine>{topLine}</TopLine>
                            </ScrollText>
                            <ScrollText className="fly" isVisible={Visibility[1].isVisible} id={`fly-${id}${Visibility[1].id}`}>
                            <Heading lightText={lightText}>{headline}</Heading>
                            </ScrollText>
                            <ScrollText className="fly" isVisible={Visibility[2].isVisible} id={`fly-${id}${Visibility[2].id}`}>
                            <Subtitle darkText={darkText}>
                            <p>1. Wybierz datę i godzinę spotkania</p>
                            <p>2. Wypełnij krótką antiektę</p>
                            <p>3. Czekaj na potwierdzenie mailowe</p>
                            <p>4. Spotkanie odbędzie się w wybranym terminie.</p>
                            </Subtitle>
                            </ScrollText>
                            <ScrollText className="fly" isVisible={Visibility[3].isVisible} id={`fly-${id}${Visibility[3].id}`}>
                            {button === true ?
                            
                            <BtnWrap>
                                <ButtonS to={link}
                                {...blank ? {target: '_blank'} : null}
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                                primary={primary ? 1 : 0}
                                dark={dark ? 1 : 0}
                                dark2={dark2 ? 1 : 0}
                                >
                                    {buttonLabel}
                                </ButtonS>
                            </BtnWrap>
                            
                            : null
                            }    
                            </ScrollText>   
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ImgWrap>
                            <ScrollText2 className="fly" isVisible={Visibility[4].isVisible} id={`fly-${id}${Visibility[4].id}`}>
                            <Img src={img} alt={alt}/>
                            </ScrollText2>
                        </ImgWrap>
                    </Column2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    </>
  )
}

export default Help