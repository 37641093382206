export const helpObjOne = {
    id: 'help',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Rezerwacje',
    headline: 'Jak zarezerwować wizytę?',
    description: '1. Wybierz termin wizyty z kalendarza. \n 2. Wybierz godzinę wizyty. \n 3. Wypełnij formularz. \n 4. Wybierz sposób płatności. \n 5. Potwierdź rezerwację.',
    buttonLabel: 'Wybierz termin',
    imgStart: true,
    img: require('../../images/svg15.svg').default,
    alt: 'notes',
    dark: false,
    primary: true,
    darkText: true,
    button: true,
    link: 'calendar',
    blank: false
}