import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import Booking from '../components/Booking'
import Help from '../components/Help'
import { helpObjOne } from '../components/Help/Data'

const BookingPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }
  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Help {...helpObjOne}/>
    <Booking />
    <Footer />
    </>
  )
}

export default BookingPage