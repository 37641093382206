import React, { useState } from 'react'
import Hero3 from '../components/Hero3'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import InfoSection from '../components/InfoSection'
import { kontaktObjOne, kontaktObjTwo, kontaktObjThree } from '../components/InfoSection/Data'

const Kontakt = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Navbar toggle={toggle} />
    <Sidebar isOpen={isOpen} toggle={toggle} />
    <Hero3 />
    <InfoSection {...kontaktObjOne}/>
    <InfoSection {...kontaktObjTwo}/>
    <InfoSection {...kontaktObjThree}/>
    <Footer />
    </>
  )
}

export default Kontakt