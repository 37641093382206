import React, {useState} from 'react'
import { Nav, NavbarContainer, NavLogo, NavItem, NavIcon, MobileIcon, NavLinks, NavMenu, ExternalLink } from './NavbarElements'
import { FaBars, FaCaretDown } from 'react-icons/fa'
import  image from '../../images/ATENIDA-logo2-01.svg'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import Dropdown from '../Dropdown/Dropdown';
import Dropdown2 from '../Dropdown2/Dropdown2';
import { useLocation } from 'react-router-dom';
import { RequireAdminComp } from '../RequireAuth'


const Navbar = ({ toggle }) => {
    const toggleHome = () => {
        //scroll 20 px down to avoid navbar covering the top of the page
        scroll.scrollTo(20);
        scroll.scrollToTop()
    }

    const [dropdown, setDropdown] = useState(false);
    const [dropdown2, setDropdown2] = useState(false);
    const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
      ]);
      const navigate = useNavigate();
    
      function logOut() {
        signOut();
        navigate('/');
      }

    const onMouseEnter = () => {
        if(window.innerWidth <960){
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    const onMouseLeave = () => {
        if(window.innerWidth <960){
            setDropdown(false);
        } else {
            setDropdown(false);
        }
    }

    const onMouseEnter2 = () => {
        if(window.innerWidth <960){
            setDropdown2(false);
        } else {
            setDropdown2(true);
        }
    }

    const onMouseLeave2 = () => {
        if(window.innerWidth <960){
            setDropdown2(false);
        } else {
            setDropdown2(false);
        }
    }
    const { pathname } = useLocation();
  return (
    <>
        <Nav>
            <NavbarContainer>
                <NavLogo to='/' onClick={toggleHome}><NavIcon src={image} /></NavLogo>
                <MobileIcon onClick={toggle}>
                    <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to='/' onClick={toggleHome} className={pathname === "/" ? "active" : ""}>Strona główna</NavLinks>
                    </NavItem>
                    <NavItem>
                    <ExternalLink
                        href="https://sklep.atenida.pl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sklep
                    </ExternalLink>
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/news' onClick={toggleHome} className={pathname === "/news" ? "active" : ""}>Artykuły</NavLinks>
                    </NavItem>
                    <NavItem
                    onClick={toggleHome} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}
                    className={( pathname === "/oferta/kursy" || pathname === "/oferta/pakiety-masterclass" || pathname === "/oferta/sesje" || pathname === "/booking") ? "active" : ""}
                    >
                        <NavLinks>Oferta <FaCaretDown /></NavLinks>
                        {dropdown && <Dropdown />
                        
                        }
                    </NavItem>
                    <NavItem
                    onClick={toggleHome} onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}
                    className={(pathname === "/omnie" || pathname === "/life-transition-coach" || pathname === "/styl-pracy" || pathname === "/opinie" || pathname === "/konsultacje") ? "active" : ""}
                    >
                        <NavLinks>Atenida <FaCaretDown /></NavLinks>
                        {dropdown2 && <Dropdown2 />
                        
                        }
                    </NavItem>
                    <NavItem>
                        <NavLinks to='/kontakt' onClick={toggleHome} className={pathname === "/kontakt" ? "active" : ""}>Kontakt</NavLinks>
                    </NavItem>

                    <NavItem>
                        {route === 'authenticated' ? (
                        <NavLinks to='/' onClick={() => logOut()}>Wyloguj</NavLinks>)
                        : (<NavLinks to='/login'>Zaloguj</NavLinks>)}
                    </NavItem>
                    <RequireAdminComp>
                    <NavItem>
                        <NavLinks to='/adminpage' onClick={toggleHome} className={pathname === "/adminpage" ? "active" : ""}>Administracja</NavLinks>
                    </NavItem>
                    </RequireAdminComp>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    </>
  )
}

export default Navbar