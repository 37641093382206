import styled from "styled-components";

export const HeroContainer = styled.div`
    // background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 870px;
    position: relative;
    z-index: 1;
    
    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(150, 150, 150, 0.2) 0%, rgba(250, 250, 250, 0.8) 100%),
        linear-gradient(180deg, rgba(200, 200, 200, 0.6) 0%, transparent 100%);
        z-index: 2;
    }
    min-height: 592px;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    top: 0;
    // z-index: 0;
    //overflow: hidden;
    background: linear-gradient(
        108deg,
        rgba(220, 213, 201, 1) 0%,
        rgba(190, 179, 160, 1) 100%
    );
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    min-width: 50%;
    padding: 8px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        min-width: 100%;
        padding: 0;
    }
`;

export const HeroH1 = styled.h1`
    color: #465D56;
    font-size: 56px;
    text-align: center;
    font-family: 'HelloParisSansExtraLight';
    
    @media screen and (max-width: 768px) {
        font-size: 40px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 30px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #465D56;
    font-size: 32px;
    text-align: center;
    max-width: 700px;

    @media screen and (max-width: 768px) {
        font-size: 28px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 16px;
        max-width: 480px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export const Logo = styled.img`
    height: 400px;
    margin-top: -80px;

    @media screen and (max-width: 768px) {
        height: 300px;
    }
`;

export const Column = styled.div`
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
    }
`;

export const Linka = styled.a`
    color: #4E4D4A;
    font-size: 32px;
    text-align: center;
    max-width: 600px;
    text-decoration: none;

    &:hover{
        color: #fff;
    }
    
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`;




